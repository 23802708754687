#App {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* minimum height of the viewport */
}

#App-main {
    flex: 1; /* take up all available space */
}

footer {
    /* footer styles if any */
}
